import React, {useState, useEffect} from 'react'
import sanityClient from "../client.js";
// import GenericGridTwoCols from './GenericGridTwoCols.js'
import GenericGridThreeCols from './GenericGridThreeCols.js'

const Portraits = () => {

    // const { slug } = useParams()
    const slug = "Portraits"
    const baseurl = "/photos/"

    const [photoData, setPhotoData] = useState([]);

    useEffect(() => {
        sanityClient
        .fetch(`*[_type == "category" && title == "${slug}"]{
            title,
            _id,
            "photos": *[_type == "photo" && references(^._id)] | order(title asc){
                title,
                slug,
                alt, 
                mainImage{
                    asset->{
                        _id,
                        url
                    }
                }
            }
            
           
        }`)
        .then((data) => setPhotoData(data[0]))
        .catch(console.error);
    }, [])

    console.log(photoData)

    if (!photoData) return <div>Loading...</div>
  return (
    <main className="min-h-screen sm:p-12 mb-48 sm:mb-0 mt-12 sm:mt-0">
       <GenericGridThreeCols photos={photoData.photos} baseurl={baseurl}/>   
    </main>
  )
}

export default Portraits
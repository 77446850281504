import React, {useState, useEffect} from 'react'
import sanityClient from "../client.js";
import { Link } from 'react-router-dom';


const CV2 = () => {

    const [cVData, setCvData] = useState(null)
    const [sortedCvData, setSortedDvData] = useState(null)

    useEffect(() => {
        sanityClient
        .fetch(`*[_type == "cvItem"]{
            title,
            cvCategory,
            subCategory,
            year,
            link,
            description   
        }`)
        .then((data) => setCvData(data))
        .catch(console.error);
    }, [])



 

    useEffect(()=> {
        const sortArray = () => {
            let header = []
            for (let i = 0; i < cVData.length; i++){
                                if(cVData[i].cvCategory === "Header"){
                    header.push(cVData[i])
                }
            }
            let edArr = []
            for (let i = 0; i < cVData.length; i++){
                                if(cVData[i].cvCategory === "Education"){
                    edArr.push(cVData[i])
                }
            }
            let exArr = []
            for (let i = 0; i < cVData.length; i++){
                                if(cVData[i].cvCategory === "Exhibitions"){
                    exArr.push(cVData[i])
                }
            }
            let pubArr = []
            for (let i = 0; i < cVData.length; i++){
                                if(cVData[i].cvCategory === "Publications"){
                    pubArr.push(cVData[i])
                }
            }
            let pressArr = []
            for (let i = 0; i < cVData.length; i++){
                                if(cVData[i].cvCategory === "Press"){
                    pressArr.push(cVData[i])
                }
            }
            let musArr = []
            for (let i = 0; i < cVData.length; i++){
                                if(cVData[i].cvCategory === "Music Covers"){
                    musArr.push(cVData[i])
                }
            }
            let other = []
            for (let i = 0; i < cVData.length; i++){
                        if(cVData[i].cvCategory === "Other Work"){
                            other.push(cVData[i])
                        }
            }


            setSortedDvData([header, edArr, exArr, pubArr, pressArr, musArr, other])
        }
        cVData && sortArray()  
    },[cVData])

    const sortMusicCoversByYear = (a, b) => {
        if ( a.year < b.year ){
            return -1;
          }
          if ( a.year > b.year ){
            return 1;
          }
          return 0;
    }


    function compare( a, b ) {
        if ( a.last_nom < b.last_nom ){
          return -1;
        }
        if ( a.last_nom > b.last_nom ){
          return 1;
        }
        return 0;
      }

      const formatLongText = (string) => {
        let arr = string.split("#")
        return arr
    }

    console.log(sortedCvData)

    if(!sortedCvData) return <div>loading...</div>
  return (
    <main className="min-h-screen p-4 sm:p-12 sm:mx-64 mt-12">
        <div>
        <h1 className="text-3xl font-bold mb-8">CV</h1>
        </div>
        <section>
            {/* Header */}
           <div className="py-4">
            <h4 className="font-bold">{sortedCvData && sortedCvData[0][0].title}</h4>
            <div className="">
                {
                    sortedCvData[0] && formatLongText(sortedCvData[0][0].description).map((item, index)=>(
                        <div className="" key={index}>
                            <p>{item}</p>
                            
                        </div>
                    ))
                }
            </div>
           
           </div>
            {/* Education */}
           <div className="py-4">
            <h4 className="font-bold">{sortedCvData && sortedCvData[1][0].cvCategory}</h4>
            {
                sortedCvData && sortedCvData[1].map((item, index)=>(
                    <div key={index}>
                    {item.link ? <Link to={{pathname: item.link}} target="_blank"><p className="underline hover:text-red-500 cursor-pointer">- {item.title}</p></Link> : <p>- {item.title}</p>}
                </div>
                ))
            }
           </div>
            {/* Exhibitions */}
           <div className="py-4">
            <h4 className="font-bold">{sortedCvData && sortedCvData[2][0].cvCategory}</h4>
            {
                sortedCvData && sortedCvData[2].map((item, index)=>(
                    <div key={index}>
                    {item.link ? <Link to={{pathname: item.link}} target="_blank"><p className="underline hover:text-red-500 cursor-pointer">- {item.title}</p></Link> : <p>- {item.title}</p>}
                </div>
                ))
            }
           </div>

           {sortedCvData && console.log(sortedCvData[3].length)}
            {sortedCvData && sortedCvData[3].length === 0 ? <></> : 
           
        //    Publications
           <div className="py-4">
            <h4 className="font-bold">{sortedCvData && sortedCvData[3][0].cvCategory}</h4>
            {
                sortedCvData[3] && sortedCvData[3].map((item, index)=>(
                    <div key={index}>
                    {item.link ? <Link to={{pathname: item.link}} target="_blank"><p className="underline hover:text-red-500 cursor-pointer">- {item.title}</p></Link> : <p>- {item.title}</p>}
                </div>
                ))
            }
           </div>        
            }
        {/* Press */}
            <div className="py-4">
                <h4 className="font-bold">{sortedCvData && sortedCvData[4][0].cvCategory}</h4>
                {
                    sortedCvData && sortedCvData[4].sort(compare).map((item, index)=>(
                        <div key={index}>
                        {item.link ? <Link to={{pathname: item.link}} target="_blank"><p className="underline hover:text-red-500 cursor-pointer">- {item.title}</p></Link> : <p>- {item.title}</p>}
                    </div>
                    ))
                }
           </div>
                {/* Music Covers */}
           <div className="py-4">
                <h4 className="font-bold">{sortedCvData && sortedCvData[5][0].cvCategory}</h4>
                {
                    sortedCvData && sortedCvData[5].sort(sortMusicCoversByYear).reverse().map((item, index)=>(
                        <div key={index}>
                        {item.link ? <Link to={{pathname: item.link}} target="_blank"><p className="underline hover:text-red-500 cursor-pointer">- {item.title}</p></Link> : <p>- {item.title}</p>}
                    </div>
                    ))
                }
           </div>

           <div className="py-4">
                <h4 className="font-bold">{sortedCvData && sortedCvData[6][0].cvCategory}</h4>
                {
                    sortedCvData && sortedCvData[6].sort(compare).map((item, index)=>(
                        <div key={index}>
                        {item.link ? <Link to={{pathname: item.link}} target="_blank"><p className="underline hover:text-red-500 cursor-pointer">- {item.title}</p></Link> : <p>- {item.title}</p>}
                    </div>
                    ))
                }
           </div>
        </section>
    </main>
  )
}

export default CV2
import React from 'react'
import { NavLink } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons'

const NavBar = () => {
    return (
        <header className="bg-white sm:pt-6 px-4 shadow-lg invisible h-0 sm:visible sm:h-auto">
            <div className="bg-white container mx-auto flex justify-between" >
                <nav className="sm:flex">
                    <NavLink
                    to="/"
                    exact
                    activeClassName=""
                    className="inline-flex items-center py-2 px-2 sm:px-0 mr-4 text-gray-800 sm:text-lg font-bold title tracking-widest">
                        Audrey Bizouerne Photography
                    </NavLink>
                    {/* <NavLink to="/photos" className="inline-flex items-center py-2 px-3 my-4 rounded text-gray-800 hover:text-red-500"
                    activeClassName="underline">
                        Photos
                    </NavLink> */}
                    <NavLink to="/projects" className="text-sm inline-flex items-center py-2 px-3 my-4 rounded text-gray-800 hover:text-red-500" 
                    activeClassName="underline">
                        Projects
                    </NavLink>
                    <NavLink to="/portrait" className="text-sm inline-flex items-center py-2 px-3 my-4 rounded text-gray-800 hover:text-red-500"
                    activeClassName="underline">
                        Portraits
                    </NavLink>
                    <NavLink to="/music" className="text-sm inline-flex items-center py-2 px-3 my-4 rounded text-gray-800 hover:text-red-500"
                    activeClassName="underline">
                        Music Covers
                    </NavLink>
                    <NavLink to="/publications" className="text-sm inline-flex items-center py-2 px-3 my-4 rounded text-gray-800 hover:text-red-500"
                    activeClassName="underline">
                        Publications
                    </NavLink>
                    <NavLink to="/exhibitions" className="text-sm inline-flex items-center py-2 px-3 my-4 rounded text-gray-800 hover:text-red-500"
                    activeClassName="underline">
                        Exhibitions
                    </NavLink>
                    <NavLink to="/contact" className="text-sm inline-flex items-center py-2 px-3 my-4 rounded text-gray-800 hover:text-red-500"
                    activeClassName="underline">
                        Contact
                    </NavLink>
                    <NavLink to="/cv" className="text-sm inline-flex items-center py-2 px-3 my-4 rounded text-gray-800 hover:text-red-500"
                    activeClassName="underline">
                        CV
                    </NavLink>
                    {/* <NavLink to="/shop" className="inline-flex items-center py-2 px-3 my-4 rounded text-gray-800 hover:text-red-500"
                    activeClassName="underline">
                        Shop
                    </NavLink> */}

                </nav>
                <div className="text-sm inline-flex py-2 px-2 sm:px-0 my-4 mx-0">
                    <SocialIcon url="https://www.instagram.com/audreybizouernephoto" className="mr-4" target="_blank" fgColor="#fff" bgColor="#252626" style={{ height: 24, width: 24} }/>

                </div>
            </div>
        </header>
    )
}

export default NavBar

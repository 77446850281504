import React, {useEffect, useState} from 'react'
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
// import { FaExternalLinkAlt } from "react-icons/fa";


const CV = () => {

    const [cv, setCV] = useState(null)

    useEffect(()=>{
        sanityClient
        .fetch(`*[_type == "cv"]{
            title,
            subtitle,
            body,
            highlight
        }`)
        .then((data)=> setCV(data[0]))
        .catch(console.error)
    },[])

    console.log(cv)
    if (!cv) return <div>Loading...</div>
  return (
    <main className="min-h-screen p-4 sm:p-12 sm:mx-64 mt-12" >
    <div>
        <h1 className="text-3xl font-bold mb-8">{cv.title}</h1>
        <p className="text-2xl mb-4">{cv.subtitle}</p>
        <BlockContent blocks={cv.body} projectId="jbvpy92a" dataset="production" className="group"/>
    </div>
    </main>
  )
}

export default CV
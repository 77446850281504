import React from 'react'
import { Link } from 'react-router-dom';


const ImageHover = ({photo, baseurl}) => {
  return (

    
                <Link to={photo.slug ? baseurl+photo.slug.current : baseurl} className="">
                <div className="p-2 w-full relative group">
                    <img src={photo.mainImage.asset.url} className="w-full"/>
                    <div className="opacity-0 group-hover:opacity-100 duration-300 absolute inset-0 bottom-0 flex justify-center items-center text-6xl text-white font-semibold">{photo.title}</div>
                </div>
                </Link>
  )
}

export default ImageHover
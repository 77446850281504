import React, {useState, useEffect} from 'react'
// import PhotoGridSmall from './PhotoGridSmall.js'
// import PhotoGridMobile from './PhotoGridMobile.js'
import sanityClient from "../client.js";
import GenericGridThreeCols from './GenericGridThreeCols.js'
import HomePageOne from './HomePageOne.js';


const Home = () => {
    

    const [photoData, setPhotoData] = useState(null);
    const baseurl = "/photos/"

    useEffect(() => {
        sanityClient
        .fetch(`*[_type == "photo" && group == "home"]{
            title,
            alt,
            group,
            categories,
            slug,
            description,
            mainImage{
                asset->{
                    _id,
                    url
                }
            }
        }`)
        .then((data) => setPhotoData(data))
        .catch(console.error);
    }, [])

    if (!photoData) return <div>Loading...</div>
    return (
       <main className="min-h-screen sm:p-12 mb-32 z-0">
           {/* <div className="sm:hidden">
            <PhotoGridMobile />
           </div>
          
           <div className="">
           <PhotoGridSmall />
           </div> */}
           {
            photoData.length >2 ?
            <GenericGridThreeCols photos={photoData} baseurl={baseurl}/> 
            : <HomePageOne photos={photoData}/> 
           } 
       </main>
    )
}

export default Home

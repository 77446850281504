import React, {useState} from 'react'
import { NavLink } from 'react-router-dom'
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";


const BurgerNav = () => {

    const [showMobileNav, setShowMobileNav] = useState(false)

    const burger = () => {
        setShowMobileNav(!showMobileNav)
    }

  return (
    <nav className="bg-white border-b border-slate-400 sm:hidden w-full absolute z-30">
        <button className="flex p-1 transition ease-in-out delay-150 inline-flex items-center" onClick={burger}>
            {
                showMobileNav ? <><ImCross className="p-1"/> <h6 className="pl-2">MENU</h6></>
                :
                <><GiHamburgerMenu />  <h6 className="pl-2">MENU</h6></>
            }
            
            </button>
        
        {
            showMobileNav ? 
            <div className="bg-white container w-auto px-8 transition ease-in-out delay-150 w-32 ">
         <nav className="flex flex-col underline">
                    <NavLink
                    to="/"
                    exact
                    activeClassName=""
                    className="inline-flex items-center py-2 px-2 sm:px-0 mr-4 text-gray-800 sm:text-lg font-bold title tracking-widest">
                        Audrey Bizouerne Photography
                    </NavLink>
                    {/* <NavLink to="/photos" className="inline-flex items-center py-2 px-3 my-4 rounded text-gray-800 hover:text-red-500"
                    activeClassName="underline">
                        Photos
                    </NavLink> */}
                    <NavLink to="/projects" className="text-sm inline-flex items-center py-2 px-3  rounded text-gray-800 hover:text-red-500" 
                    activeClassName="underline">
                        Projects
                    </NavLink>
                    <NavLink to="/portrait" className="text-sm inline-flex items-center py-2 px-3  rounded text-gray-800 hover:text-red-500"
                    activeClassName="underline">
                        Portraits
                    </NavLink>
                    <NavLink to="/music" className="text-sm inline-flex items-center py-2 px-3  rounded text-gray-800 hover:text-red-500"
                    activeClassName="underline">
                        Music Covers
                    </NavLink>
                    <NavLink to="/publications" className="text-sm inline-flex items-center py-2 px-3  rounded text-gray-800 hover:text-red-500"
                    activeClassName="underline">
                        Publications
                    </NavLink>
                    <NavLink to="/exhibitions" className="text-sm inline-flex items-center py-2 px-3  rounded text-gray-800 hover:text-red-500"
                    activeClassName="underline">
                        Exhibitions
                    </NavLink>
                    <NavLink to="/contact" className="text-sm inline-flex items-center py-2 px-3  rounded text-gray-800 hover:text-red-500"
                    activeClassName="underline">
                        Contact
                    </NavLink>
                    <NavLink to="/cv" className="text-sm inline-flex items-center py-2 px-3  rounded text-gray-800 hover:text-red-500"
                    activeClassName="underline">
                        CV
                    </NavLink>
                    {/* <NavLink to="/shop" className="inline-flex items-center py-2 px-3  rounded text-gray-800 hover:text-red-500"
                    activeClassName="underline">
                        Shop
                    </NavLink> */}

                </nav>
    </div> : <div></div>
        }
    
    </nav>
  )
}

export default BurgerNav
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import sanityClient from '../client.js'
import { Link } from 'react-router-dom';

const Photo = () => {

    const [photo, setPhoto] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        sanityClient.fetch(`*[slug.current == "${slug}"]{
            title,
            alt,
            group,
            slug,
            description,
            showTitle,
            mainImage{
                asset->{
                    _id,
                    url
                }
            }
            ,
            image2{
                asset->{
                    _id,
                    url
                }
            },
            image3{
                asset->{
                    _id,
                    url
                }
            },
            link
        }`)
        .then((data) => setPhoto(data[0]))
        .catch(console.error);
    }, [slug]);

    if(!photo) return <div>Loading...</div>

    console.log(photo)

    return (
        <main className="bg-gray-200 min-h-screen sm:p-12">
            <article className="container shadow-lg mx-auto bg-white pb-16 pt-8 ">
                <header className="flex justify-center items-center">
                    
                    <img src={photo.mainImage.asset.url} alt={photo.title} className="sm:w-2/3 sm:p-10 p-6 object-cover"/>
                    
                </header>
                <div className="px-16 py-12 lg:py-20 mx-auto flex justify-center items-center">
                    <div className="">
                        <div className="bg-white py-4">
                            {photo.showTitle ?  <h1 className="font-bold">{photo.title}</h1> :  null}
                           
                        </div>
                        <p className="">{photo.description}</p>
                        
                        {photo.link ? <Link to={{pathname: photo.link}} target="_blank"><p className="hover:text-red-500 cursor:pointer">{photo.link}</p></Link>: null}
                        
                        
                    </div>
                </div>

            {
                photo.image2 ? 
               <div className="flex justify-center items-center mx-16">
               <div className="px-12">
                   <img src={photo.image2.asset.url} alt="" />
               </div>
             {photo.image3 ?  <div className="px-12">
                   <img src={photo.image3.asset.url} alt="" />
               </div> : null}
              </div>
              :
              null
            }
             
             
         
            </article>
        </main>
    )
}

export default Photo

import React, {useState, useEffect} from 'react'
import sanityClient from "../client.js";
import GenericGridThreeCols from './GenericGridThreeCols.js'

const Music = () => {

    // const { slug } = useParams()
    const slug = "Music Covers"
    const baseurl = "/photos/"

    const [photoData, setPhotoData] = useState([]);

    useEffect(() => {
        sanityClient
        .fetch(`*[_type == "category" && title == "${slug}"]{
            title,
            _id,
            "photos": *[_type in ["photo", "project"] && references(^._id)] | order(title asc){
                title,
                slug,
                alt, 
                mainImage{
                    asset->{
                        _id,
                        url
                    }
                }
            }
            
           
        }`)
        .then((data) => setPhotoData(data[0]))
        .catch(console.error);
    }, [])


  return (
    <main className="min-h-screen p-12 mb-48">
        <GenericGridThreeCols photos={photoData.photos} baseurl={baseurl}/>
    </main>
  )
}

export default Music
import React, {useState, useEffect} from 'react'
import sanityClient from "../client.js";
import GenericGridThreeCols from './GenericGridThreeCols.js'

const Projects = () => {

    const [projectData, setProjectData] = useState(null);

    const baseurl = "/projects/"

    useEffect(() => {
        sanityClient.fetch(`*[_type == "project"]{
            title,
            subtitle,
            categories,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            image1{
                asset->{
                    _id,
                    url
                }
            }
        }`)
        .then((data) => setProjectData(data))
        .catch(console.error)
    }, []);


    // const imageRender = (project) => {
    //     if(!project.mainImage){
    //         return <img src={project.image1 ? project.image1.asset.url : null} alt={project.subtitle} className="w-full" />
    //     }else{
    //         return <img src={project.mainImage.asset.url} alt={project.subtitle} className="w-full"/>
    //     }
    // }

    // const varyPaddingY = (index) => {
    //     if(index % 3 === 0){
    //         return "py-24"
    //     }
    //     if(index % 2 === 0){
    //         return "py-18"
    //     }
    //     if(index % 7 === 0){
    //         return "py-32"
    //     }
    //     if(index % 5 === 0){
    //         return "py-36"
    //     }
    //     if(index % 1 === 0){
    //         return "py-12"
    //     }
    // }


    // const varyPaddingX = (index) => {
    //     if(index % 3 === 0){
    //         return "px-12"
    //     }
    //     if(index % 2 === 0){
    //         return "px-8"
    //     }
    //     if(index % 7 === 0){
    //         return "px-4"
    //     }
    //     if(index % 5 === 0){
    //         return "px-4"
    //     }
    //     if(index % 1 === 0){
    //         return "px-16"
    //     }
    // }

    if (!projectData) return <div>Loading...</div>

    return (
        <main className="min-h-screen sm:p-12 sm:mb-0 mb-48 mt-12 sm:mt-0">
        <GenericGridThreeCols photos={projectData} baseurl={baseurl}/>   
        </main>
    //    <main className="min-h-screen p-4 sm:p-12">
    //        <section className="mx-6 bg-white">
               
    //            <section className="container mx-auto">

    //             <div>
    //             {/* <h1 className="text-gray-800 text-xl w-32 font-bold py-4 bg-white title text-gray-800">Projects</h1> */}
    //             </div>
                
    //             <div className="grid grid-flow-row grid-cols-1 sm:grid-cols-2  gap-4 justify-items-center">
    //             {projectData && projectData.map((project, index) => (
                    
    //                 <article key={index} className={` hover:opacity-80 `}>
    //                     <Link to={"/projects/"+project.slug.current} key={project.slug.current}>
    //                         {console.log("/projects/"+project.slug.current)}
                            
    //                         <div className="p-6 ">
    //                         <div key={index} className="">
    //                            {imageRender(project)}
    //                            {/* <div className="absolute opacity-0 inset-x-2/4 inset-y-2/4 text-white group-hover:opacity-100"><h1>{project.title}</h1></div> */}
    //                        </div>
    //                        <div className="flex flex-row">
    //                            <h3 className="text-gray-800 text-l font-bold px-1 py-2 title text-gray-800">
    //                             {project.title}
    //                            </h3>
    //                            <p className="text-gray-800 text-l px-3 py-2 title text-gray-600">{project.subtitle}</p>
    //                        </div>
    //                        </div>
    //                     </Link>                       
    //                 </article>
    //             ))}
    //             </div>
    //            </section>
    //        </section>
    //    </main>
    )
}

export default Projects

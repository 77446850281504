import React, {useState, useEffect} from 'react'
import sanityClient from "../client.js";
import { Link } from 'react-router-dom';
// import Categories from './Categories'
import { useLocation } from 'react-router-dom'

const Photos = () => {

    let type = useLocation();
    type = type.pathname.slice(1)


    const [photoData, setPhotoData] = useState([]);
    // const [selectedCategory, setSelectedCategory] = useState('')
    // const [categories, setCategories] = useState(null)
    const [filteredPhotos, setFilteredPhotos] = useState([])

    // useEffect(() => {
    //     sanityClient.fetch(`*[_type == "category"]{
    //         title,
    //         description
    //     }`)
    //     .then((data) => setCategories(data))
    //     .catch(console.error)
    // },[])

    const length = filteredPhotos.length

    useEffect(() => {
        sanityClient
        .fetch(`*[_type == "photo"]{
            title,
            alt,
            group,
            "categories": categories[]->{
                title,
            },
            slug,
            css,
            portrait,
            description,
            mainImage{
                asset->{
                    _id,
                    url
                }
            }
        }`)
        .then((data) => setPhotoData(data))
        .catch(console.error);
    }, [])



    // useEffect(()=> {
    //     if(selectedCategory !== ""){
    //         setFilteredPhotos(photoData && photoData.filter(p => p.categories ? p.categories[0].title === selectedCategory : null))
    //     }else{
    //         setFilteredPhotos(photoData)
    //     }
    // },[photoData, selectedCategory])

    const rowSize = 5;
    const newArr = [];
  
    const chunkArray = () => {
      for (let i = 0; i < filteredPhotos.length; i+= rowSize){
          const subset = filteredPhotos.slice(i, i + rowSize);
          newArr.push(subset);
      }
    }
  
    filteredPhotos && chunkArray();

    // add a filter to remove any photos that don't have slugs

    if (!photoData) return <div>Loading photos...</div>

    return (
       <main className="min-h-screen p-4 sm:p-12">
           <section className="container mx-auto max-w-screen">
               {/* <div className="flex">
               <h1 className="text-gray-800 text-xl w-32 font-bold py-2 bg-white title text-gray-800">Photos</h1>
               <Categories categories={categories} setSelectedCategory={setSelectedCategory} photoData={photoData} setPhotoData={setPhotoData}/>
               </div> */}

                <section className="grid grid-flow-row grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-2 justify-items-center">
                
                <div>
                {photoData && photoData.slice(0, length/2).map((photo, index) => (
                       <div className= "hover:opacity-80 p-8" key={index}>
                           <Link to={photo.slug ? "/photos/"+photo.slug.current : "/photos"} key={index}>
                           <img src={photo.mainImage ? photo.mainImage.asset.url : "/db-error.png"} alt={photo ? photo.alt : "no image"} className="w-full "/>
                           </Link>
                       </div>
                   ))
                   }
                </div>

                <div>
                {photoData && photoData.slice(length/2, length).map((photo, index) => (
                       <div className= "hover:opacity-80 p-8" key={index}>
                           <Link to={photo.slug ? "/photos/"+photo.slug.current : "/photos"} key={index}>
                           <img src={photo.mainImage ? photo.mainImage.asset.url : "/db-error.png"} alt={photo ? photo.alt : "no image"} className="w-full"/>
                           </Link>
                       </div>
                   ))
                   }
                </div>

               </section>
           </section>

           {/* <section>
                    <div>
                        {
                        newArr && newArr.map((arr, index)=>(
                            <div className="flex justify-between" key={index}>
                            {
                              arr && arr.map((item, index)=> (
                                <div className="" key={index}>
                                     <Link to={item.slug ? "/work/"+item.slug.current : "/work/"} >
                                  <img src={item.mainImage.asset.url} alt="" className="w-auto h-72 p-2 object-cover hover:brightness-50 transition ease-in-out duration-125"/>
                                  </Link>
                                </div>
                              ))
                            }
                          </div>
                        )) 

                        }
                    </div>
        </section> */}
       </main>
    )
}

export default Photos

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from './components/Home'
// import About from './components/About'
// import SinglePhoto from './components/SinglePhoto'
import Photos from './components/Photos'
// import TestPhotos from './components/TestPhotos'
import Photo from './components/Photo'

import Projects from './components/Projects'
import Portraits from './components/Portraits'
import NavBar from './components/NavBar'
import Project from './components/Project'
// import Commercials from './components/Commercials'
import Music from './components/Music'
import CV from './components/CV'
import CV2 from './components/CV2'
import Contact from './components/Contact'
// import Shop from './components/Shop'
import Exhibitions from './components/Exhibitions'
import Publications from './components/Publications'
import BurgerNav from './components/BurgerNav'


function App() {
  return (
    <BrowserRouter>
     <BurgerNav />
    <NavBar />
    <Switch>
      <Route component={Home} path="/" exact/>
      {/* <Route component={About} path="/about"/> */}
      <Route component={CV} path="/cv"/>
      <Route component={CV2} path="/cv2"/>
      <Route component={Contact} path="/contact"/>
      <Route component={Music} path="/music" />
      {/* <Route component={Shop} path="/shop"/> */}
      <Route component={Exhibitions} path="/exhibitions" />
      <Route component={Publications} path="/publications" />
      <Route component={Portraits} path="/portrait" />
      
      
      <Route component={Project} path="/projects/:slug"/>
      <Route component={Projects} path="/projects"/>

      <Route component={Photo} path="/photos/:slug"/>
      <Route component={Photos} path="/photos"/>
     
     
      
      

      {/* <Route component={Photo} path="/portrait/:slug"/>
      <Route component={PhotosByType} path="/portrait"/>

      <Route component={Photo} path="/landscape/:slug"/>
      <Route component={PhotosByType} path="/landscape"/>

      <Route component={Photo} path="/street/:slug"/>
      <Route component={PhotosByType} path="/street"/> */}
      {/* <Route component={TestPhotos} path="/test-photos"/> */}
      
      {/* <Route component={Photo} path="/commercial-images/:slug"/> */}
      {/* <Route component={Commercials} path="/commercial-images"/>  */}

    </Switch>
    </BrowserRouter>
  );
}

export default App;

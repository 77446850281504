import React from 'react'
import {Link} from 'react-router-dom'

const HomePageOne = ({photos}) => {
  console.log(photos[0])

  return (
      
        <section className="grid grid-flow-row grid-cols-1 lg:p-12  justify-items-center min-h-screen z-2">
             <div className="md:w-1/2 lg:w-2/3 xl:w-1/2 w-full h-full">

                {/* <div className= "hover:opacity-80 p-8">
                    <Link to={photos[0].slug ? "/photos/"+photos[0].slug.current : "/photos"} >
                    <img src={photos[0].mainImage ? photos[0].mainImage.asset.url : "/db-error.png"} alt={photos[0].photo ? photos[0].alt : "no image"} className=""/>
                    </Link>
                </div>

                <div className="p-8">
                  <h2>{photos[0].title}</h2>
                </div> */}

<Link to={photos[0].slug ? "/photos/"+photos[0].slug.current : "/photos"} >
                <div className= "sm:p-8 relative h-full group ">
                <div className="relative z-10 flex opacity-0 group-hover:opacity-100 duration-300 justify-center items-center text-3xl text-black font-semibold sm:px-4">{photos[0].title}</div>

                  <div className="absolute inset-0 bg-cover bg-center group-hover:opacity-50 duration-300 z-0" style={{backgroundImage: `url(${photos[0].mainImage.asset.url} `}}></div>
                  {/* <div>title</div> */}
                  {/* opacity-0 hover:opacity-100  duration-300 absolute inset-0 z-10 */}
                    {/* <Link to={photos[0].slug ? "/photos/"+photos[0].slug.current : "/photos"} >
                    <img src={photos[0].mainImage ? photos[0].mainImage.asset.url : "/db-error.png"} alt={photos[0].photo ? photos[0].alt : "no image"} className=""/>
                    </Link> */}
                </div>
                </Link> 
       
              </div>

        </section>
    


  )
}

export default HomePageOne
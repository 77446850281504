import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import sanityClient from '../client.js'
import BlockContent from "@sanity/block-content-to-react";

const Project = () => {

    const [ project, setProject ] = useState(null)
    const { slug } = useParams();

    console.log("Project")

    useEffect(() => {
        sanityClient.fetch(`*[slug.current == "${slug}"]{
            title,
            subtitle,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            mainImageParagraph,
            grid1, 
            grid2Paragraph,
            grid2,
            grid3Paragraph,
            grid3,
            image1{
                asset->{
                    _id,
                    url
                }
            }, 
            highlight,
            image2{
                asset->{
                    _id,
                    url
                }
            },
            image3{
                asset->{
                    _id,
                    url
                }
            },
            image4{
                asset->{
                    _id,
                    url
                }
            },
            image1Paragraph,
            image2Paragraph,
            image3Paragraph,
            image4Paragraph,
            notes
        }`)
        .then((data) => setProject(data[0]))
        .catch(console.error)
    },[slug]);

    console.log(project)

    const mainImageRender = () => {
        if (!project.mainImage){
            return <></>
        }else{
            return <img src={project.mainImage.asset.url} alt={project.subtitle} className="py-8"/>
        }
    }

    const mainImageParagraphRender = () => {
        if(!project.mainImageParagraph) {
            return null
        }else{
            return <p className="py-8">{project.mainImageParagraph}</p>
        }
    }
   
    const image1Render = () => {
        if(!project.image1) {
            return null
        }else{
            return <img src={project.image1.asset.url} alt={project.subtitle}  className="py-4"/>
        }
    }

    const image1ParagraphRender = () => {
        if(!project.image1Paragraph){
            return null
        }else{
            return <p>{project.image1Paragraph}</p>
        }
    }

    const image4Render = () => {
        if(!project.image4) {
            return null
        }else{
            return <img src={project.image4.asset.url} alt="" />
        }
    }

    const image4ParagraphRender = () => {
        if(!project.image4Paragraph){
            return null
        }else{
            return <p>{project.image4Paragraph}</p>
        }
    }

    const grid1Render = () => {
        if(!project.grid1){
            return null
        }else{
            return <BlockContent blocks={project.grid1.filter(grid => grid._type === "image")} projectId="jbvpy92a" dataset="production" className="grid sm:grid-cols-2 gap-8 pb-8 lg:mx-32"/>
        }
    }

    const highlightRender = () => {
        if(!project.highlight){
            return null
        }else{
            return <p>{project.highlight}</p>
        }
    }

    const grid2Render = () => {
        if(!project.grid2){
            return null
        }else{
            return <BlockContent blocks={project.grid2.filter(grid => grid._type === "image")} projectId="jbvpy92a" dataset="production" className="grid sm:grid-cols-2 gap-8 pb-8 lg:mx-32"/>
        }
    }

    const grid2ParagraphRender = () => {
        if(!project.grid2Paragraph){
            return null
        }else{
            return <p>{project.grid2Paragraph}</p>
        }
    }

    const grid3Render = () => {
        if(!project.grid3){
            return null
        }else{
            return <BlockContent blocks={project.grid3.filter(grid => grid._type === "image")} projectId="jbvpy92a" dataset="production" className="grid sm:grid-cols-2 gap-8 pb-8 lg:mx-32"/>
        }
    }

    const grid3ParagraphRender = () => {
        if(!project.grid3Paragraph){
            return null
        }else{
            return <p>{project.grid3Paragraph}</p>
        }
    }

    const image2Render = () => {
        if(!project.image2){
            return null
        }else{
            return <img src={project.image2.asset.url} alt="" />
        }
    }

    const image2ParagraphRender = () => {
        if(!project.image2Paragraph){
            return null
        }else{
            return <p>{project.image2Paragraph}</p>
        }
    }

    const image3Render = () => {
        if(!project.image3){
            return null
        }else{
            return <img src={project.image3.asset.url} alt="" />
        }
    }

    const image3ParagraphRender = () => {
        if(!project.image3Paragraph){
            return null
        }else{
            return  <p>{project.image3Paragraph}</p>
        }
    }

    


    const notesRender = () => {
        if(!project.notes){
            return null
        }else{
            return  <p>{project.notes}</p>
        }
    }

    if(!project) return <div>Loading...</div>
    return (
        <main className="min-h-screen sm:px-12 px-6 py-6">
            <section className="container mx-auto">
            <article>
                
                <header className="pt-8">
                    <div>
                        <div className="sm:px-6 lg:px-10 prose lg:prose-l pb-8">
                            <h1 className="title text-1xl lg:text-2xl">{project.title}</h1> 
                            {project.subtitle ? <h3 className="title text-1xl lg:text-2xl">{project.subtitle}</h3> : null}
                        </div>
                        <div className="lg:mx-64 md:mx-32">
                            {mainImageRender()}
                        </div>
                        {/* <div className="px-10 lg:px-20 py-0 lg:py-0 prose lg:prose-l mx-auto bg-red-500">
                            {mainImageParagraphRender()}
                        </div> */}
                    </div>
                </header>

                <section>
                <div className="px-10 lg:px-20 lg:py-0  prose lg:prose-l mx-auto ">
                            {mainImageParagraphRender()}
                </div>
                </section>

                <section>
                    <div className="flex justify-center mx-auto lg:mx-32">
                        {image1Render()}
                    </div>
                    <div className="px-10 lg:px-20 py-0 lg:py-0 prose lg:prose-xl max-w-full">
                        {image1ParagraphRender()}
                    </div>
                </section>

                <section>
                    <div className="">
                        {grid1Render()}
                    </div>
                    <div>
                        {highlightRender()}
                    </div>
                </section>

                <section className="py-4">
                    <div className="">
                        {grid2Render()}
                    </div>
                    <div className="px-10 lg:px-20 py-0 lg:py-0 prose lg:prose-xl max-w-full">
                        {grid2ParagraphRender()}
                    </div>
                </section>

                <section className="py-4">
                    <div className="flex justify-center mx-auto lg:mx-32">
                        {image2Render()}
                    </div>
                    <div className="px-10 lg:px-20 py-0 lg:py-0 prose lg:prose-xl max-w-full">
                        {image2ParagraphRender()}
                    </div>
                </section>

                <section className="py-4">
                    <div className="">
                        {grid3Render()}
                    </div>
                    <div className="px-10 lg:px-20 py-0 lg:py-0 prose lg:prose-xl max-w-full">
                        {grid3ParagraphRender()}
                    </div>
                </section>

          

                <section className="py-4">
                    <div className="flex justify-center mx-auto lg:mx-32">
                        {image3Render()}
                    </div>
                    <div className="px-10 lg:px-20 py-0 lg:py-0 prose lg:prose-xl max-w-full">
                        {image3ParagraphRender()}
                    </div>
                </section>

                <section className="py-4">
                    <div className="flex justify-center mx-auto lg:mx-32">
                        {image4Render()}
                    </div>
                    <div className="px-10 lg:px-20 py-0 lg:py-0 prose lg:prose-xl max-w-full">
                        {image4ParagraphRender()}
                    </div>
                </section>

                <section className="py-4">
                    <div className="px-15 lg:px-30 py-0 lg:py-0 prose lg:prose-xl max-w-full">
                        {notesRender()}
                    </div>
                </section>

            </article>
            </section>
        </main>
    )
}

export default Project

import React from 'react'
// import { Link } from 'react-router-dom';
import ImageHover from './ImageHover'


const GenericGridThreeCols = ({photos, baseurl}) => {

    const length = photos && photos.length
if (length === 0) return <div>No images available</div>

  return (
    <section className="grid grid-flow-row grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-1 sm:p-8 justify-items-center">
    <div className="">
    {photos && photos.slice(0, length/3).map((photo, index) => (
        //    <div className= "hover:opacity-80 p-2" key={index}>
        //        <Link to={photo.slug ? baseurl+photo.slug.current : baseurl} key={index}>
        //        <img src={photo.mainImage ? photo.mainImage.asset.url : "/db-error.png"} alt={photo ? photo.alt : "no image"} className="w-full "/>
        //        </Link>
        //    </div>
           <ImageHover photo={photo} key={index} baseurl={baseurl} className=""/>
       ))
       }
    </div>
    <div>
    {photos && photos.slice(length/3, 2*(length/3)).map((photo, index) => (
        //    <div className= "hover:opacity-80 p-2" key={index}>
        //        <Link to={photo.slug ? baseurl+photo.slug.current : baseurl} key={index}>
        //        <img src={photo.mainImage ? photo.mainImage.asset.url : "/db-error.png"} alt={photo ? photo.alt : "no image"} className="w-full"/>
        //        </Link>
        //    </div>
        <ImageHover photo={photo} key={index} baseurl={baseurl} className=""/>

       ))
       }
    </div>

    <div>
    {photos && photos.slice(2*(length/3), length).map((photo, index) => (
        //    <div className= "hover:opacity-80 p-2" key={index}>
        //        <Link to={photo.slug ? baseurl+photo.slug.current : baseurl} key={index}>
        //        <img src={photo.mainImage ? photo.mainImage.asset.url : "/db-error.png"} alt={photo ? photo.alt : "no image"} className="w-full"/>
        //        </Link>
        //    </div>
        <ImageHover photo={photo} key={index} baseurl={baseurl} className=""/>
       ))
       }
    </div>

   </section>
  )
}

export default GenericGridThreeCols
import React, {useState, useEffect} from 'react'
import sanityClient from "../client.js";
import { Link } from 'react-router-dom';

const Contact = () => {

  const [contact, setContact] = useState(null)

  useEffect(() => {
      sanityClient
      .fetch(  
        `*[_type == "contact"]{
        header, 
        email, 
        phone,
        studioVisit,
        salesInfo,
        contactImage{
            asset->{
                _id,
                url
            },
        },
    }`)
      .then((data) => setContact(data[0]))
      .catch(console.error);
  }, [])



  return (
    <main className="max-h-screen p-4 sm:p-12">
    <section className="flex">
        <div className="sm:w-1/2">
        <img src="/colourfilmselfie.jpg" alt="" className="object-cover"/>
        </div>

        <div className="sm:w-1/2 mx-12 px-12">
          <h2 className="text-3xl font-bold pb-8">Contact</h2>
          <h4 className="text-2xl">Email: {contact && contact.email}</h4>

        </div>
    </section>

</main>
  )
}

export default Contact